import { Button, Card, Col, Form, Modal, Row, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis } from "../../Common/Apis";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  USER_TYPES,
} from "../../Common/Constants";
import { useNavigate } from "react-router-dom";
import History from "../../Common/Bookings/History";
import useNotify from "../Notify";
import View from "./View";
import Reschedule from "./Reschedule";
import { useDispatch, useSelector } from "react-redux";
import { useApiLoader } from "../ApiLoaderContext";
import { centalisedSubmitBtn, getRemoteConfigData } from "../../Common/Utils";
import { bookingsLocationsUrl } from "../Endpoints";
import ViewPatient from "./ViewPatient";
import Active from "./Active";
import { setTimeSlots } from "../../../Reducers/bookingsSlice";

const Bookings = ({ getUrl, redirectUrl, putUrl, locationType }) => {
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");
  const [activeTab, setActiveTab] = useState(
    userType === USER_TYPES.CLINIC_ADMIN ? "upcoming" : "active"
  );
  const [bookingList, setBookingList] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewPatientModalOpen, setPatientViewModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [content, setContent] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [locations, setLocations] = useState();
  const [location, setLocation] = useState();
  const [staticPrograms, setStaticPrograms] = useState();
  const { setShowFullLoader } = useApiLoader();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { notify } = useNotify();
  const dispatch = useDispatch();

  const getBookings = async () => {
    setShowLoader(true);
    const params =
      userType === USER_TYPES.CLINIC_ADMIN && location
        ? { type: activeTab, location: location }
        : { type: activeTab };
    await getApis(getUrl, params)
      .then((response) => {
        setBookingList(response?.data);
        setShowLoader(false);
      })
      .catch(() => {
        setBookingList();
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getRemoteConfigData("BOOKINGS").then((result) => {
      setStaticPrograms(result);
    });
    getRemoteConfigData("CONTENTS").then((result) => {
      setContent(result);
    });
    if (!locations && userType === USER_TYPES.CLINIC_ADMIN) {
      getLocations();
    }
    if (location || userType !== USER_TYPES.CLINIC_ADMIN) {
      getBookings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location]);

  const getLocations = async () => {
    await getApis(bookingsLocationsUrl, { type: locationType })
      .then((response) => {
        setLocations(response?.data);
        setLocation(response?.data?.[0]?.value);
      })
      .catch(() => {});
  };

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const cancelViewModal = () => {
    setViewModalOpen(false);
  };

  const cancelPatientViewModal = () => {
    setPatientViewModalOpen(false);
  };

  const showRescheduleModal = (record) => {
    form.resetFields();
    setSelectedRow(record);
    setRescheduleModalOpen(true);
  };

  const cancelRescheduleModal = () => {
    setRescheduleModalOpen(false);
    setSelectedRow(null);
    dispatch(setTimeSlots([]));
  };

  const getBookingDetails = async (record) => {
    setViewModalOpen(true);
    setSelectedRow(record);
  };

  const getPatientDetails = async (record) => {
    setPatientViewModalOpen(true);
    setSelectedRow(record);
  };

  const showCancelConfirm = (item) => {
    confirm({
      title: "Are you sure?",
      content:
        userType === USER_TYPES.CLINIC_ADMIN
          ? content?.clinicAdmin?.bookings?.cancel
          : content?.patient?.bookings?.cancel,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        cancelBookings(item);
      },
      onCancel() {},
    });
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  const cancelBookings = async (record) => {
    setShowFullLoader(true);
    await postApis(putUrl, {
      bookingID: record?.bookingID,
      clinicName: record?.clinic,
      action: "cancelled",
      bookedBy: record?.bookedBy,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setShowFullLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const renderComponent = (compnent) => {
    return (
      <div>
        {userType === USER_TYPES.CLINIC_ADMIN && (
          <Row
            justify={"space-between"}
            gutter={[16, 8]}
            className="flex-start-center"
          >
            <Col xs={24} sm={6} md={4} lg={2} xl={2} className="bold">
              Location:
            </Col>
            <Col xs={24} sm={18} md={20} lg={10} xl={10}>
              <Select
                title="Filter By Location"
                size="large"
                value={location}
                placeholder="Select Location"
                options={locations}
                className="full-width"
                onChange={handleLocationChange}
              />
            </Col>
          </Row>
        )}
        <div className="mt1">{compnent}</div>
      </div>
    );
  };

  const renderHistoryList = () => {
    return renderComponent(
      <History
        bookingList={bookingList}
        userType={userType}
        getBookingDetails={getBookingDetails}
        getPatientDetails={getPatientDetails}
        locationType={locationType}
      />
    );
  };

  const tabItems = [
    {
      key: "active",
      label: "Active",
      children: renderComponent(
        <Active
          bookingList={bookingList}
          userType={userType}
          showRescheduleModal={showRescheduleModal}
          getBookingDetails={getBookingDetails}
          getPatientDetails={getPatientDetails}
          showCancelConfirm={showCancelConfirm}
          locationType={locationType}
        />
      ),
    },
    {
      key: "history",
      label: "History",
      children: renderHistoryList(),
    },
    {
      key: "completed",
      label: "Completed",
      children: renderHistoryList(),
    },
    {
      key: "cancelled",
      label: "Cancelled",
      children: renderHistoryList(),
    },
    {
      key: "noshow",
      label: "No Show",
      children: renderHistoryList(),
    },
  ];

  const onClickHandler = () => {
    navigate(redirectUrl);
  };

  return (
    !showLoader && (
      <div data-testid="bookings">
        {userType !== USER_TYPES.CLINIC_ADMIN ? (
          <>
            {staticPrograms?.map((item, index) => {
              return (
                <Row key={index} gutter={[16, 16]} justify="space-around">
                  {item?.services?.map((service, subIndex) => {
                    return (
                      <Col
                        key={subIndex}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={10}
                      >
                        <Card className="orange-plane-card box-shadow">
                          <Row gutter={[16, 16]} justify="space-between">
                            <Col span={8}>
                              <img
                                src={service?.image}
                                alt="Sleep"
                                width="100%"
                              />
                            </Col>

                            <Col span={15} className="vertical-align">
                              <div className="top">
                                <div className="bold fs22 blue">
                                  {service?.label}
                                </div>
                                <div className="bold green mt05">
                                  {item?.clinic?.label}
                                </div>
                              </div>
                              {!service?.label?.includes("Fotona") &&
                              redirectUrl ? (
                                <Button
                                  type="primary"
                                  className="bottom"
                                  onClick={() =>
                                    navigate(
                                      `${redirectUrl}?clinic=${item?.clinic?.value}`
                                    )
                                  }
                                >
                                  Book Now
                                </Button>
                              ) : (
                                <Button
                                  type="primary"
                                  className="bottom"
                                  onClick={() =>
                                    navigate(ROUTES.PATIENT.FOTONA.PATH)
                                  }
                                >
                                  Book Now
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </>
        ) : (
          redirectUrl &&
          centalisedSubmitBtn(null, BUTTONS.BOOK_NEW, onClickHandler, 8)
        )}

        <Card className="mt1">
          <Tabs
            type="card"
            defaultActiveKey={activeTab}
            onChange={onTabChange}
            items={
              userType === USER_TYPES.CLINIC_ADMIN
                ? tabItems?.filter(
                    (item) => !["pending", "history"].includes(item?.key)
                  )
                : tabItems?.filter(
                    (item) =>
                      !["completed", "cancelled", "noshow"].includes(item?.key)
                  )
            }
          />
        </Card>
        {viewModalOpen && (
          <View
            modalOpen={viewModalOpen}
            cancelModal={cancelViewModal}
            details={selectedRow}
            showQR={selectedRow?.bookingType !== "SFOTO-FOTONA-NIGHTLASE"}
          />
        )}
        {viewPatientModalOpen && (
          <ViewPatient
            modalOpen={viewPatientModalOpen}
            cancelModal={cancelPatientViewModal}
            details={selectedRow}
          />
        )}
        {rescheduleModalOpen && (
          <Reschedule
            form={form}
            modalOpen={rescheduleModalOpen}
            cancelModal={cancelRescheduleModal}
            bookingsUrl={putUrl}
            selectedRow={selectedRow}
            userType={userType}
          />
        )}
      </div>
    )
  );
};

export default Bookings;
