import { Col, Layout, Row } from "antd";
import { FOOTER } from "../../Constants";

const { Footer } = Layout;

const SmallFooter = () => {
  return (
    <Footer className="white-footer">
      <Row justify={"center"}>
        <Col xs={24} sm={24} md={12} lg={7} xl={7} className="flex-center">
          <div
            dangerouslySetInnerHTML={{
              __html: FOOTER.COPYRIGHT,
            }}
          />
        </Col>
      </Row>
    </Footer>
  );
};

export default SmallFooter;
