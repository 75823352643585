import { Col, Layout, Row } from "antd";
import LOGO from "../../../Assets/images/whiteLogo.png";
import { Link, useLocation } from "react-router-dom";
import GOBACK from "../../../Assets/images/goBack.svg";
import { ROUTES } from "../Constants";
import { useEffect, useState } from "react";
import { isAndroidOrIosApp } from "../Utils";
const { Content } = Layout;

const UnauthLayout = (props) => {
  const location = useLocation();
  const [showGoBackLink, setShowGoBackLink] = useState(true);
  const isMobileApp = isAndroidOrIosApp();

  const getClassName = () => {
    if (location.pathname.includes("/patient/")) {
      return "blue-filler";
    } else if (location.pathname.includes("/provider/")) {
      return "orange-filler";
    } else if (location.pathname.includes("/clinicAdmin/")) {
      return "green-filler";
    } else {
      return "";
    }
  };

  const goBackHandler = (e) => {
    e.preventDefault();
    window.history.back();
  };

  useEffect(() => {
    if (isMobileApp) {
      setShowGoBackLink(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd4" data-testid="unauth-layout">
      <Content className="unAuthWidget">
        <Row className="mh80vh">
          <Col
            data-testid="unauth-div"
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            className={`${getClassName()} flex-center`}
          >
            {isMobileApp ? (
              <div className="text-center">
                <img src={LOGO} alt="Logo" className="login-logo" />
              </div>
            ) : (
              <Link to={ROUTES.DEFAULT.PATH} className="text-center">
                <img src={LOGO} alt="Logo" className="login-logo" />
              </Link>
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="nonFiller">
            <Row className="pd1 flex-center mb1">
              <Col xs={24} sm={24} md={24} lg={22} xl={22}>
                {showGoBackLink && (
                  <Link to={ROUTES.DEFAULT.PATH}>
                    <img
                      src={GOBACK}
                      alt="Go Back"
                      title="Go to Home"
                      className="w32 mt1l1"
                      onClick={goBackHandler}
                    />
                  </Link>
                )}
                <div className="text-center">
                  <div id="captchaDiv" className="flex-center"></div>
                  {props.children}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
export default UnauthLayout;
