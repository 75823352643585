import { Button, Card, Col, Row } from "antd";
import { useState } from "react";
import RECORD from "../../../Assets/images/Recordings/record.png";
import { BUTTONS } from "../../Common/Constants";
import AudioAnalyser from "react-audio-analyser";

const Recordings = () => {
  const [status, setStatus] = useState("");
  const [audioSrc, setAudioSrc] = useState(null);

  const controlAudio = (status) => {
    setStatus(status);
  };

  const audioProps = {
    audioType: "audio/wav",
    status,
    audioSrc,
    timeslice: 1000,
    startCallback: (e) => {
      console.log("succ start", e);
    },
    pauseCallback: (e) => {
      console.log("succ pause", e);
    },
    stopCallback: (e) => {
      setAudioSrc(window.URL.createObjectURL(e));
      console.log("succ stop", e);
    },
    onRecordCallback: (e) => {
      console.log("recording", e);
    },
    errorCallback: (err) => {
      console.log("error", err);
    },
  };

  return (
    <div>
      <Card className="p0-blue-card">
        <Row justify={"space-between"}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} className="pd2">
            <div className="fs24 bold">Record Your Speech</div>
            <p>
              Create a clear and noise-free voice recording for optimal health
              assessment.
            </p>
          </Col>
          <Col xs={0} sm={0} md={4} lg={4} xl={4} style={{ lineHeight: 0 }}>
            <img src={RECORD} alt="Record" width="100%" />
          </Col>
        </Row>
      </Card>
      <Card className="mt1">
        <div className="bold blue fs20">Please follow instruction:</div>
        <ol className="grey">
          <li className="mt05">
            Please make sure you have no background noises while recording your
            speech.
          </li>
          <li className="mt05">
            Please record your speech when your comfortable and sitted.
          </li>
          <li className="mt05">
            Please record your speech in your normal voice and speed.
          </li>
          <li className="mt05">
            Try to use earphones to record your speech for better results.
          </li>
          <li className="mt05">
            To assess your health, we recommend you to do this once a week.
          </li>
        </ol>
        <Button type="primary" className="ml1 mt1">
          {BUTTONS.CONTINUE}
        </Button>

        <div className="mt1">
          <AudioAnalyser {...audioProps}>
            <div className="btn-box">
              <button className="btn" onClick={() => controlAudio("recording")}>
                Start
              </button>
              <button className="btn" onClick={() => controlAudio("paused")}>
                Pause
              </button>
              <button className="btn" onClick={() => controlAudio("inactive")}>
                Stop
              </button>
            </div>
          </AudioAnalyser>
        </div>
      </Card>
    </div>
  );
};

export default Recordings;
