import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Dropdown } from "antd";

const ActionMenu = ({ record, showRefundConfirm, onStatusActionChange }) => {
  const menuItems = [];

  if (record?.fileUrl) {
    menuItems.push({
      key: "viewOrderForm",
      label: (
        <div
          onClick={() => window.open(record?.fileUrl, "_blank")}
          className="action-item"
        >
          <EyeOutlined className="mr05" />
          View Order Form
        </div>
      ),
    });
  }

  if (record.status !== "COMPLETED" && record?.questionnaireStatus === true) {
    menuItems.push({
      key: "cancel",
      label: (
        <div onClick={() => showRefundConfirm(record)} className="action-item">
          <CloseCircleOutlined className="mr05" />
          Cancel
        </div>
      ),
    });
  }

  if (record.status === "HOLD") {
    if (record?.questionnaireStatus === true) {
      menuItems.push({
        key: "pending",
        label: (
          <div
            onClick={() => onStatusActionChange("PENDING", record)}
            className="action-item"
          >
            <ExclamationCircleOutlined className="mr05" />
            Pending
          </div>
        ),
      });
    } else {
      menuItems.push({
        key: "resolve",
        label: (
          <div
            onClick={() => onStatusActionChange("RESOLVED", record)}
            className="action-item"
          >
            <CheckOutlined className="mr05" />
            Resolve
          </div>
        ),
      });
    }
  } else if (record.status === "PENDING") {
    menuItems.push({
      key: "complete",
      label: (
        <div
          onClick={() => onStatusActionChange("COMPLETED", record)}
          className="action-item"
        >
          <CheckCircleOutlined className="mr05" />
          Complete
        </div>
      ),
    });
  }

  return (
    <Dropdown
      menu={{
        items: menuItems,
      }}
    >
      <Button>
        Actions <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ActionMenu;
