import { Button, Col, Form, Row, Select } from "antd";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";

const AddClinic = ({
  isAddModalOpen,
  handleAddModalCancel,
  onFinish,
  onFinishFailed,
  loading,
  filteredClinics,
}) => {
  return (
    <Popup
      title={"Find Your Clinic"}
      open={isAddModalOpen}
      cancel={handleAddModalCancel}
      footer={null}
      width={600}
    >
      <Form
        initialValues={{}}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Form.Item
          label={false}
          name="clinics"
          size="large"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            showSearch
            placeholder="Search..."
            options={filteredClinics}
          />
        </Form.Item>

        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default AddClinic;
