import { Checkbox, Col, Divider, Form, Input, Radio, Row, Space } from "antd";
import { useState } from "react";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";
import Popup from "../../Common/Popup";
import { centalisedSubmitBtn, displayUserName } from "../../Common/Utils";

const Questionnaire = ({
  asthma,
  loading,
  onFinish,
  onFinishFailed,
  questionnaires,
  enrolledPrograms,
  questionnaireModalOpen,
  handleQuestionnaireCancel,
}) => {
  const [form] = Form.useForm();
  const [deliveryMethod, setDeliveryMethod] = useState();

  const getCurrentDate = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  return (
    <Popup
      title={false}
      open={questionnaireModalOpen}
      cancel={handleQuestionnaireCancel}
      footer={null}
      width={950}
      style={{ top: "25px" }}
    >
      {enrolledPrograms?.map((program, index) => {
        const procedures =
          asthma === false
            ? questionnaires?.[program?.clinic]?.[
                program?.service
              ]?.procedures.filter(
                (item) =>
                  item !==
                  "I attest that I check my peak flow level before every dose and only proceed when it is above 80% of my personal best."
              )
            : questionnaires?.[program?.clinic]?.[program?.service]?.procedures;
        return (
          questionnaires?.[program?.clinic]?.[program?.service] && (
            <div key={index}>
              <div className="bold fs18">
                {questionnaires?.[program?.clinic]?.[program?.service]?.heading}
              </div>
              <Divider style={{ margin: "0.5rem 0 1rem 0" }} />
              <ul className="bold">
                {questionnaires?.[program?.clinic]?.[
                  program?.service
                ]?.instructions?.map((ins, inIndex) => {
                  return <li key={inIndex}>{ins}</li>;
                })}
              </ul>
              <Form
                initialValues={{}}
                form={form}
                layout="vertical"
                size="large"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="mt2"
                validateMessages={{
                  required: VALIDATIONS.DEFAULT.BLANK,
                }}
              >
                {questionnaires?.[program?.clinic]?.[
                  program?.service
                ]?.questions?.map((question, qIndex) => {
                  return (
                    <div key={qIndex}>
                      <Form.Item
                        label={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: question?.title,
                            }}
                          />
                        }
                        name={`question${qIndex + 1}`}
                        size="large"
                        rules={[
                          {
                            required: true,
                            message: "Please select an option",
                          },
                        ]}
                      >
                        <Radio.Group className="mt05">
                          {["Yes", "No"]?.map((opt, index) => {
                            return (
                              <Radio key={index} value={opt}>
                                {opt}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        name={`question${qIndex + 1}Reason`}
                        dependencies={[`question${qIndex + 1}`]}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const selectedOption = getFieldValue(
                                `question${qIndex + 1}`
                              );
                              if (selectedOption === "Yes" && !value) {
                                return Promise.reject(
                                  new Error(VALIDATIONS.DEFAULT.BLANK)
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  );
                })}

                <Form.Item name="procedures" label={false}>
                  <Checkbox.Group>
                    {procedures?.map((method, index) => (
                      <Checkbox value={`p${index + 1}`} key={index}>
                        {method}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>

                <Form.Item
                  name="deliveryMethod"
                  label={"Delivery Method"}
                  rules={[
                    {
                      required: true,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setDeliveryMethod(e.target.value);
                    }}
                    value={deliveryMethod}
                  >
                    <Space direction="vertical">
                      {questionnaires?.[program?.clinic]?.[
                        program?.service
                      ]?.deliveryMethods.map((method, index) => {
                        return (
                          <Radio value={`d${index + 1}`} key={index}>
                            {method}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>

                {deliveryMethod === "d2" && (
                  <Form.Item
                    label={false}
                    name={"shippingAddress"}
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                <Form.Item
                  name="acknowledge"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject("Please acknowledge it");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Checkbox>
                    {
                      questionnaires?.[program?.clinic]?.[program?.service]
                        ?.acknowledge
                    }
                  </Checkbox>
                </Form.Item>

                <Row gutter={[16, 16]} justify="space-between" className="bold">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    {displayUserName()}
                  </Col>
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    {getCurrentDate()}
                  </Col>
                </Row>

                <Form.Item>
                  {centalisedSubmitBtn(loading, BUTTONS.SUBMIT, null, 4)}
                </Form.Item>
              </Form>
            </div>
          )
        );
      })}
    </Popup>
  );
};

export default Questionnaire;
