import { InfoCircleFilled } from "@ant-design/icons";
import { Col, Divider, Result, Row } from "antd";
import { useSelector } from "react-redux";
import { BUTTONS, ROUTES } from "../../../Common/Constants";
import { centalisedSubmitBtn, rowDataWithIcon } from "../../../Common/Utils";
import { displayFotonaConfirmInfo } from "./Info";

const Confirm = () => {
  const bookingDetails =
    useSelector((state) => state?.bookings?.details) ||
    JSON.parse(localStorage.getItem("bookingDetails"));

  const onClickHandler = () => {
    localStorage.removeItem("bookingDetails");
    window.location.assign(ROUTES.PATIENT.BOOKINGS.PATH);
  };

  const cardBody = () => {
    if (bookingDetails) {
      return (
        <div>
          {displayFotonaConfirmInfo(bookingDetails)?.map((item, index) => {
            return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
          })}
          {centalisedSubmitBtn(false, BUTTONS.DONE, onClickHandler, 6)}
        </div>
      );
    } else {
      return (
        <Result
          subTitle="No data found"
          extra={centalisedSubmitBtn(false, BUTTONS.DONE, onClickHandler, 6)}
        />
      );
    }
  };

  return (
    <Row justify={"center"} className="mt2">
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <div className="bold fs20 blue">
          <div className="flex-start-center">
            <InfoCircleFilled className="icon24 mr05" /> Booking Status
          </div>
        </div>
        <Divider style={{ margin: "0.5rem 0 2rem 0" }} />
        {cardBody()}

        <div>
          <Divider style={{ margin: "3rem 0 0 0" }} />

          <div className="mt1 bold fs13">INSURANCE DISCLAIMER</div>
          <div className="mt1 fs13 paragraph108">
            Every effort will be made by our office to verify your insurance
            benefits and if applicable, to have all services and procedures pre
            authorized by your insurance. However, verification of benefits
            and/or authorization does not guarantee payment. Payments of
            benefits are subject to all terms, conditions, limitations, and
            exclusions of the member's contract at time of service. If your
            health insurance company determines that a particular service is not
            reasonable and medically necessary, your insurer will deny payment
            for that service. Ultimately, the patient will be held financially
            responsible for payment of all services provided. You have the
            option of paying out of pocket in the event your procedure is denied
            by your insurance carrier. Authorization requests, when applicable,
            will be submitted only once the date of surgery is confirmed.
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Confirm;
