import { Card, Empty, Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis, putApis } from "../../Common/Apis";
import { NOTIFICATIONS, USER_TYPES } from "../../Common/Constants";
import {
  patientDeviceDetailsUrl,
  patientUpdateProfileUrl,
} from "../../Common/Endpoints";
import useNotify from "../../Common/Notify";
import {
  convertHeightToCm,
  convertLbsToKg,
  getRemoteConfigData,
  postMessageToMobileApp,
  logAnalyticsEvent,
  setUserDetails,
  isAndroidOrIosApp,
} from "../../Common/Utils";
import Connect from "./Connect";
import ConnectRing from "./ConnectRing";
import List from "./List";

const ConnectedDevices = () => {
  const userDetails =
    useSelector((state) => state?.profile?.profileDetails) ||
    JSON.parse(localStorage.getItem("userDetails"));
  const [deviceDetails, setDeviceDetails] = useState();
  const [deviceImages, setDeviceImages] = useState();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [isRingModalOpen, setIsRingModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const deviceConnectionDetails = JSON.parse(
    localStorage.getItem("deviceConnectionDetails")
  );

  const showRingModal = (type) => {
    if (type && type === "edit") {
      setIsRingModalOpen(true);
      setIsListModalOpen(false);
    } else {
      if (
        userDetails?.feet &&
        userDetails?.inches &&
        userDetails?.lbsWeight &&
        userDetails?.skinColor
      ) {
        const height = convertHeightToCm(
          userDetails?.feet,
          userDetails?.inches
        );
        const weight = convertLbsToKg(userDetails?.lbsWeight);
        postMessageToMobileApp(
          {
            height: height,
            weight: weight,
            skinColor: userDetails?.skinColor,
            age: userDetails?.age,
            gender: userDetails?.gender,
          },
          "connectToDevice"
        );
        setIsListModalOpen(false);
      } else {
        setIsRingModalOpen(true);
        setIsListModalOpen(false);
      }
    }
  };

  const handleRingModelCancel = () => {
    setIsRingModalOpen(false);
  };

  const showListModal = () => {
    setIsListModalOpen(true);
    setIsRingModalOpen(false);
  };

  const handleListCancel = () => {
    setIsListModalOpen(false);
  };

  const getDeviceDetails = async () => {
    setShowLoader(true);
    await getApis(patientDeviceDetailsUrl)
      .then((response) => {
        setShowLoader(false);
        setDeviceDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const onFinish = async (values) => {
    setLoading(true);
    const height = convertHeightToCm(values?.feet, values?.inches);
    const weight = convertLbsToKg(values?.lbsWeight);
    postMessageToMobileApp({ ...values, height, weight }, "connectToDevice");
    await putApis(patientUpdateProfileUrl, values)
      .then((response) => {
        if (isAndroidOrIosApp()) {
          logAnalyticsEvent("mobile_connect_ring", {
            userType: USER_TYPES.PATIENT,
            data: userDetails?.email,
          });
        }
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setLoading(false);
        setIsRingModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  useEffect(() => {
    getDeviceDetails();
    getRemoteConfigData("DEVICES").then((result) => {
      setDeviceImages(result?.images);
      setDevices(result?.devices);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!showLoader && (
        <>
          {deviceDetails ? (
            <>
              <List
                deviceDetails={deviceDetails}
                deviceImages={deviceImages}
                remoteConfigDevices={devices}
                deviceConnectionDetails={deviceConnectionDetails}
                showListModal={showListModal}
                showRingModal={showRingModal}
              />
              {isListModalOpen && (
                <Connect
                  userDetails={userDetails}
                  deviceConnectionDetails={deviceConnectionDetails}
                  isListModalOpen={isListModalOpen}
                  handleListCancel={handleListCancel}
                  devices={devices?.filter(
                    (item) => item?.value === "Saiwell_Ring"
                  )}
                  showRingModal={showRingModal}
                />
              )}
              {isRingModalOpen && (
                <ConnectRing
                  form={form}
                  loading={loading}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  userDetails={userDetails}
                  isRingModalOpen={isRingModalOpen}
                  handleRingModelCancel={handleRingModelCancel}
                />
              )}
            </>
          ) : (
            <Card>
              <Empty />
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default ConnectedDevices;
