import React from "react";
import { Table, Button, Dropdown, Space } from "antd";
import { getPageHeader } from "../../../Common/Utils";
import {
  ClockCircleOutlined,
  CloseOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const Active = ({
  bookingList,
  showRescheduleConfirm,
  getBookingDetails,
  getPatientDetails,
  showCancelConfirm,
}) => {
  const parentColumns = [
    {
      title: "Patient",
      dataIndex: "identifier",
      key: "identifier",
      render: (_, record) =>
        `${record?.childrenData[0]?.firstName} ${record?.childrenData[0]?.lastName}`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          size="small"
          type="primary"
          className="text-center"
          icon={<CloseOutlined />}
          onClick={() => showCancelConfirm(record, true)}
        >
          Cancel All
        </Button>
      ),
    },
  ];

  const childColumns = [
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
    },
    {
      title: "Payment",
      dataIndex: "stripePaymentStatus",
      render: (_, record) => {
        return record?.stripePaymentStatus
          ? record?.stripePaymentStatus === "NA"
            ? record?.stripePaymentStatus
            : getPageHeader(record?.stripePaymentStatus)
          : "NA";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Dropdown
          className="text-center"
          menu={{
            items: [
              {
                key: "1",
                label: "Booking Details",
                icon: <EyeOutlined />,
                onClick: () => getBookingDetails(record),
              },
              {
                key: "2",
                label: "Patient Details",
                icon: <EyeOutlined />,
                onClick: () => getPatientDetails(record),
              },
              {
                key: "3",
                label: "Reschedule",
                icon: <ClockCircleOutlined />,
                onClick: () => showRescheduleConfirm(record),
              },
              {
                key: "4",
                label: "Cancel",
                icon: <CloseOutlined />,
                onClick: () => showCancelConfirm(record),
              },
            ],
          }}
        >
          <Button size="small">
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const parentData = bookingList?.map((children, index) => ({
    key: index,
    childrenData: children,
  }));

  return (
    <Table
      columns={parentColumns}
      dataSource={parentData}
      expandable={{
        expandIcon: ({ expanded, onExpand, record }) => (
          <span
            onClick={(e) => onExpand(record, e)}
            style={{
              display: "inline-block",
              width: "24px",
              height: "24px",
              fontSize: "20px",
              lineHeight: "24px",
              textAlign: "center",
              backgroundColor: "#464646",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            {expanded ? "-" : "+"}
          </span>
        ),
        expandedRowRender: (record) => (
          <Table
            columns={childColumns}
            dataSource={record.childrenData}
            rowKey="bookingID"
            pagination={false}
          />
        ),
        rowExpandable: (record) => record.childrenData.length > 0,
      }}
      pagination={false}
    />
  );
};

export default Active;
