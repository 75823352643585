import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { BUTTONS, FORM_FIELDS, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
import { getPageHeader, validateFutureDate } from "../../Common/Utils";
import Autocomplete from "react-google-autocomplete";
import { MaskedInput } from "antd-mask-input";

const Join = ({
  form,
  isJoinModalOpen,
  handleJoinModalCancel,
  onFinish,
  onFinishFailed,
  loading,
  mandatoryFields,
  optionalFields,
  providers,
  staticDropdownData,
  userDetails,
}) => {
  const dropdownFormField = (label, data, item) => {
    return (
      <Form.Item
        label={label}
        name={item}
        size="large"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select placeholder="Please Select" options={data} />
      </Form.Item>
    );
  };

  const inputFormField = (label, item) => {
    return (
      <Form.Item
        label={item === "mrn" ? FORM_FIELDS.MRN : label}
        name={item}
        size="large"
        rules={[
          {
            required: true,
          },
          item === "mrn" && {
            pattern: /^[0-9]+$/,
            message: VALIDATIONS.PATTERN.NUMERIC,
          },
        ]}
      >
        <Input
          placeholder={item === "mrn" ? FORM_FIELDS.MRN : getPageHeader(item)}
          readOnly={item === "mrn" && userDetails?.mrn}
          disabled={item === "mrn" && userDetails?.mrn}
        />
      </Form.Item>
    );
  };

  const dateFormField = (label, item) => {
    return (
      <Form.Item
        label={label}
        name={item}
        size="large"
        rules={[
          {
            required: true,
          },
          {
            validator: validateFutureDate,
          },
        ]}
      >
        <MaskedInput mask={"00/00/0000"} placeholder="MM/DD/YYYY" />
      </Form.Item>
    );
  };

  const showRequiredFields = (item) => {
    switch (item) {
      case "provider":
        return dropdownFormField(
          FORM_FIELDS.PROVIDER,
          providers?.map((i, index) => {
            return { key: index, label: i, value: i };
          }),
          item
        );
      case "surgeryLocation":
        return dropdownFormField(
          FORM_FIELDS.SURGERY_LOCATION,
          staticDropdownData?.patients?.surgeryLocation,
          item
        );
      case "surgeryDate":
        return dateFormField(FORM_FIELDS.SURGERY_DATE, item);
      default:
        return inputFormField(getPageHeader(item), item);
    }
  };

  return (
    <Popup
      title={"Join Program"}
      open={isJoinModalOpen}
      cancel={handleJoinModalCancel}
      footer={null}
      width={600}
    >
      <Form
        initialValues={{}}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        {mandatoryFields?.map((item, index) => {
          return <div key={index}>{showRequiredFields(item)}</div>;
        })}

        {optionalFields?.map((item, index) => {
          return item === "addressLine1" ? (
            <Row key={index}>
              <Col span={23}>
                <Form.Item
                  label={`${getPageHeader(item)} (Optional)`}
                  name={item}
                  size="large"
                >
                  <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_LOCATIONS_APY_KEY}
                    placeholder={getPageHeader(item)}
                    className="ant-autocomplete"
                    onPlaceSelected={(place) => {
                      form.setFieldsValue({
                        addressLine1: place.formatted_address,
                      });
                    }}
                    onBlur={() => {
                      form.setFieldsValue({
                        addressLine1: "",
                      });
                    }}
                    onChange={(value) => {
                      if (!value) {
                        form.setFieldsValue({
                          addressLine1: "",
                        });
                      }
                    }}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "us" },
                    }}
                    readOnly={userDetails?.addressLine1}
                    disabled={userDetails?.addressLine1}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : ["notifications", "emails"].includes(item) ? (
            <Form.Item
              key={item}
              name={item}
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>
                {item === "notifications"
                  ? "Allow to receive notifications from SAiWELL"
                  : "Allow to receive emails from SAiWELL"}
              </Checkbox>
            </Form.Item>
          ) : (
            <Form.Item
              key={index}
              label={`${getPageHeader(item)} (Optional)`}
              name={item}
              size="large"
            >
              <Input placeholder={getPageHeader(item)} />
            </Form.Item>
          );
        })}

        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default Join;
