import { Button, Input, Form, Row, Col, Flex } from "antd";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setOtpSent } from "../../../../Reducers/authSlice";
import Timer from "../../Timer";
import { useEffect, useState } from "react";
import Loader from "../../Loader";
import { signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../firebase";
import useNotify from "../../Notify";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  VALIDATIONS,
} from "../../Constants";
const { Title, Text } = Typography;

const StepTwo = ({ setCurrentStep }) => {
  const [loading, setLoading] = useState(false);
  const authDetails = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { notify } = useNotify();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!window.confirmationResult) {
      setCurrentStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendOtp = () => {
    signInWithPhoneNumber(
      auth,
      authDetails?.phoneNumber,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        dispatch(setOtpSent(true));
      })
      .catch((error) => {
        notify(NOTIFICATIONS.ERROR.KEY, error?.message);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    window.confirmationResult
      .confirm(values?.otp)
      .then(() => {
        dispatch(setOtpSent(false));
        setLoading(false);
        setCurrentStep(2);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          NOTIFICATIONS.ERROR.KEY,
          error?.message?.includes("invalid-verification-code")
            ? "Invalid Passcode"
            : error?.message
        );
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  return (
    <div data-testid="stepTwo">
      <div className="media-mt-3">
        <Title>Verification</Title>
        <Text>One Time Passcode has been sent to your phone</Text>
        <br />
        <Text>Please enter your One Time Passcode</Text>
      </div>
      <div className="flex-center">
        <Form
          className="mt2"
          initialValues={{}}
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            width: 400,
          }}
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Form.Item
            name="otp"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={FORM_FIELDS.OTP} maxLength={6} />
          </Form.Item>

          <Row justify={"start"} className="mt1">
            {authDetails?.otpSent ? (
              <Col span={24}>
                <Flex wrap="wrap" gap="small" className="align-center">
                  <div className="grey">Resend code in</div>
                  <Timer />
                </Flex>
              </Col>
            ) : (
              <Col span={24}>
                <Link className="left" onClick={resendOtp}>
                  Resend Code
                </Link>
              </Col>
            )}
          </Row>

          <Form.Item className="mt2">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.VERIFY} />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default StepTwo;
