import { Button, Card, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis, putApis } from "../../Common/Apis";
import {
  patientProgramProvidersUrl,
  patientProgramsUrl,
  patientUpdateProfileUrl,
  patientVerifyMrnUrl,
} from "../../Common/Endpoints";
import {
  getRemoteConfigData,
  isAndroidOrIosApp,
  reloadPatientPrograms,
  setUserDetails,
} from "../../Common/Utils";
import CLINIC from "../../../Assets/images/clinic.png";
import { NOTIFICATIONS, SERVICES } from "../../Common/Constants";
import { Link } from "react-router-dom";
import Join from "./Join";
import useNotify from "../../Common/Notify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import AddClinic from "./AddClinic";
import { setProfileDetails } from "../../../Reducers/profileSlice";

const PatientPrograms = () => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const userDetails =
    useSelector((state) => state?.profile?.profileDetails) ||
    JSON.parse(localStorage.getItem("userDetails"));
  const [staticPrograms, setStaticPrograms] = useState();
  const [dynamicPrograms, setDynamicPrograms] = useState();
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState();
  const [optionalFields, setOptionalFields] = useState();
  const [selectedClinic, setSelectedClinic] = useState();
  const [selectedService, setSelectedService] = useState();
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [filteredClinics, setFilteredClinics] = useState();
  const [providers, setProviders] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [form] = Form.useForm();
  const { notify } = useNotify();
  const dispatch = useDispatch();

  const showModal = (clinic, service, mandatoryFields, optionalFields) => {
    setSelectedClinic(clinic);
    setSelectedService(service);
    setMandatoryFields(mandatoryFields);
    setOptionalFields(optionalFields);
    setIsJoinModalOpen(true);
    form.resetFields();
    form.setFieldsValue({
      mrn: userDetails?.mrn,
      addressLine1: userDetails?.addressLine1,
      addressLine2: userDetails?.addressLine2,
    });
  };

  const getProviderList = async () => {
    if (selectedClinic && selectedService) {
      await getApis(patientProgramProvidersUrl, {
        clinic: selectedClinic,
        service: selectedService,
      })
        .then((response) => {
          setProviders(response?.data);
        })
        .catch(() => {});
    }
  };

  const handleJoinModalCancel = () => {
    setIsJoinModalOpen(false);
  };

  const handleAddModalCancel = () => {
    setIsAddModalOpen(false);
  };

  const getDynamicPrograms = async () => {
    setShowLoader(true);
    await getApis(patientProgramsUrl)
      .then((response) => {
        setShowLoader(false);
        setDynamicPrograms(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getDynamicPrograms();
    getRemoteConfigData("PROGRAMS").then((result) => {
      setStaticPrograms(result);
    });
    getRemoteConfigData("DROPDOWNS").then((result) => {
      setStaticDropdownData(result);
      setFilteredClinics(
        result?.common?.clinics?.filter(
          (item) => !userDetails?.clinics?.includes(item.value)
        )
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProviderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinic, selectedService]);

  const verifyMrn = async (formData) => {
    if (userDetails?.mrn) {
      joinProgram(formData);
    } else {
      if (formData?.mrn) {
        await postApis(patientVerifyMrnUrl, {
          mrn: formData?.mrn,
        })
          .then((response) => {
            if (response?.data?.mrnExists) {
              setLoading(false);
              notify(NOTIFICATIONS.ERROR.KEY, response?.data?.message);
            } else {
              setLoading(true);
              joinProgram(formData);
            }
          })
          .catch(() => setLoading(false));
      } else {
        joinProgram(formData);
      }
    }
  };

  const joinProgram = async (formData) => {
    await postApis(patientProgramsUrl, formData)
      .then((response) => {
        setLoading(false);
        setIsJoinModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        reloadPatientPrograms();
      })
      .catch(() => setLoading(false));
  };

  const onFinish = (values) => {
    setLoading(true);
    let updatedValues = {
      ...values,
      clinic: selectedClinic,
      service: selectedService,
    };
    if (selectedService === SERVICES.PRE_POST_PROCEDURE) {
      updatedValues = {
        ...updatedValues,
        surgeryDate: moment(values?.surgeryDate).format("MM-DD-YYYY"),
      };
    }
    verifyMrn(updatedValues);
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const onAddFinish = async (values) => {
    setLoading(true);
    await putApis(patientUpdateProfileUrl, {
      clinics: userDetails?.clinics
        ? [...values?.clinics, ...userDetails?.clinics]
        : values?.clinics,
    })
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        notify(NOTIFICATIONS.SUCCESS.KEY, "Clinic Added Successfully!");
        reloadPatientPrograms();
      })
      .catch(() => setLoading(false));
  };

  const onAddFinishFailed = () => {
    setLoading(false);
  };

  const program = (clinic) => {
    return staticPrograms?.find((p) => p?.clinic?.value === clinic);
  };

  return (
    !showLoader && (
      <div>
        {isJoinModalOpen && (
          <Join
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            isJoinModalOpen={isJoinModalOpen}
            handleJoinModalCancel={handleJoinModalCancel}
            loading={loading}
            mandatoryFields={mandatoryFields}
            optionalFields={optionalFields}
            providers={providers}
            staticDropdownData={staticDropdownData}
            userDetails={userDetails}
          />
        )}
        {isAddModalOpen && (
          <AddClinic
            onFinish={onAddFinish}
            onFinishFailed={onAddFinishFailed}
            isAddModalOpen={isAddModalOpen}
            handleAddModalCancel={handleAddModalCancel}
            loading={loading}
            filteredClinics={filteredClinics}
          />
        )}
        <div className="blue-card">
          <Row justify={"space-between"}>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              {filteredClinics?.length > 0 ? (
                <div className="pd2">
                  <div className="fs24 bold">
                    Explore Programs from Other Clinics
                  </div>

                  <p className="mt1">
                    Looking for programs offered by other clinics? Simply search
                    for a clinic in the search bar, click submit, and discover
                    the programs available.
                  </p>
                  <div className="mt105">
                    <Button
                      onClick={() => setIsAddModalOpen(true)}
                      block={isMobile ? true : false}
                    >
                      Find Your Clinic
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="pd2">
                  <div className="fs24 bold">You're All Caught Up!</div>
                  <p className="mt1">
                    No new clinics are available to join at the moment. We're
                    actively working to add more clinics and will have updates
                    for you soon!
                  </p>
                </div>
              )}
            </Col>
            <Col
              xs={0}
              sm={0}
              md={5}
              lg={5}
              xl={5}
              className={isMobile ? "" : "flex-center"}
            >
              <img src={CLINIC} alt="clinic" width="100%" />
            </Col>
          </Row>
        </div>
        {userDetails?.clinics?.map((clinic, index) => {
          return (
            <Row key={index} className="mt1">
              <Col span={24}>
                <Card title={program(clinic)?.clinic?.label}>
                  <Row gutter={[16, 16]} key={index} className="mt1">
                    {program(clinic)?.services?.map((service, sIndex) => {
                      return (
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} key={sIndex}>
                          <Card
                            styles={{ body: { padding: "1rem" } }}
                            className="orange-plane-card"
                          >
                            <Row gutter={[16, 16]}>
                              <Col
                                xs={10}
                                sm={10}
                                md={10}
                                lg={10}
                                xl={10}
                                style={{ lineHeight: 0 }}
                              >
                                <img
                                  src={service?.image}
                                  alt="Program"
                                  width="100%"
                                />
                              </Col>
                              <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                <div className="blue bold fs16">
                                  {service?.label}
                                </div>
                                {!isAndroidOrIosApp() && (
                                  <div>
                                    <Link to={service?.knowMore}>
                                      Know More
                                    </Link>
                                  </div>
                                )}

                                {dynamicPrograms &&
                                  (dynamicPrograms?.[
                                    program(clinic)?.clinic?.value
                                  ]?.includes(service?.value) ? (
                                    <div className="bold green mt1">
                                      Enrolled
                                    </div>
                                  ) : (
                                    <Button
                                      type="primary"
                                      size="small"
                                      className="mt1"
                                      onClick={() =>
                                        showModal(
                                          program(clinic)?.clinic?.value,
                                          service?.value,
                                          service?.mandatoryFields,
                                          service?.optionalFields
                                        )
                                      }
                                    >
                                      Join Now
                                    </Button>
                                  ))}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card>
              </Col>
            </Row>
          );
        })}
      </div>
    )
  );
};

export default PatientPrograms;
