import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetAuthState, setLoggedOut } from "../../Reducers/authSlice";
import { resetBookingDetails } from "../../Reducers/bookingsSlice";
import { resetFilterState } from "../../Reducers/filterSlice";
import { resetProfileState } from "../../Reducers/profileSlice";
import { ROUTES } from "./Constants";
import { isAndroidOrIosApp, logout } from "./Utils";

const Logout = () => {
  const isMobileApp = isAndroidOrIosApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    logout("logout");
    dispatch(setLoggedOut());
    dispatch(resetAuthState());
    dispatch(resetProfileState());
    dispatch(resetFilterState());
    dispatch(resetBookingDetails());
    if (isMobileApp) {
      navigate(ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP);
    } else {
      navigate(ROUTES.DEFAULT.PATH);
    }
    delete window.recaptchaVerifier;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return "";
};

export default Logout;
