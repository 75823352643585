import {
  AppstoreOutlined,
  BellOutlined,
  CaretRightOutlined,
  ClusterOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HomeOutlined,
  ImportOutlined,
  LinkOutlined,
  LogoutOutlined,
  MedicineBoxOutlined,
  ProfileOutlined,
  ProjectOutlined,
  SafetyOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  StrikethroughOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

export const ROUTES = {
  SAIGEWARE: "https://www.saigeware.com",
  DEFAULT: {
    PATH: "/",
    LABEL: "Home",
  },
  LANDING: {
    PATH: "/landing",
  },
  LOGOUT: {
    LABEL: "Logout",
    PATH: "/logout",
    ICON: <LogoutOutlined className="menu-icon" />,
  },
  DIVIDER: {
    LABEL: "divider",
    PATH: "divider",
    TYPE: "divider",
    ICON: "divider",
  },
  PATIENT: {
    LOGIN: {
      LABEL: "Patient Login",
      PATHS: {
        EMAIL: "/patient/login",
        SEND_OTP: "/patient/sendOtp",
        VERIFY_OTP: "/patient/verifyOtp",
        RESEND_OTP: "/patient/resendOtp",
      },
    },
    FORGOT_PASSWORD: {
      LABEL: "Forgot Password",
      PATH: "/patient/forgotPassword",
    },
    RESET_PASSWORD: {
      LABEL: "Reset Password",
      PATH: "/patient/resetPassword",
    },
    REGISTER: {
      LABEL: "Experience Now",
      PATH: "/patient/registration",
    },
    DASHBOARD: {
      LABEL: "Dashboard",
      PATH: "/patient/dashboard",
      ACTUAL_PATH: "/patient/dashboard/:clinic/:service",
      ICON: <ProjectOutlined className="menu-icon" />,
    },
    PROGRAMS: {
      LABEL: "Programs",
      PATH: "/patient/programs",
      ICON: <AppstoreOutlined className="menu-icon" />,
    },
    WEATHER: {
      LABEL: "Weather",
      PATH: "/patient/weather",
      ICON: <GlobalOutlined className="menu-icon" />,
    },
    PROFILE: {
      LABEL: "Profile",
      PATH: "/patient/profile",
      ICON: <EditOutlined className="menu-icon" />,
    },
    RECORDINGS: {
      LABEL: "Recordings",
      PATH: "/patient/recordings",
      ICON: <CaretRightOutlined className="menu-icon" />,
    },
    EDIT_PROFILE: {
      LABEL: "Edit Profile",
    },
    CONNECTED_DEVICES: {
      LABEL: "Connected Devices",
      PATH: "/patient/connectedDevices",
      ICON: <LinkOutlined className="menu-icon" />,
    },
    BOOKINGS: {
      LABEL: "Sleep Therapy",
      PATH: "/patient/sleepTherapy",
      ICON: <TagsOutlined className="menu-icon" />,
    },
    ORDERS: {
      LABEL: "Order Now",
      PATH: "/patient/orders",
      ICON: <ShoppingCartOutlined className="menu-icon" />,
    },
    QUESTIONNAIRES: {
      LABEL: "Questionnaires",
      PATH: "/patient/questionnaires",
      ICON: <FileTextOutlined className="menu-icon" />,
    },
    TIMELINES: {
      LABEL: "Timelines",
      PATH: "/patient/timelines",
      ICON: <FieldTimeOutlined className="menu-icon" />,
    },
    SLEEP_STUDY: {
      LABEL: "New Sleep Study",
      PATH: "/patient/sleepStudy",
      ICON: <ImportOutlined className="menu-icon" />,
    },
    FOTONA: {
      LABEL: "FOTONA",
      PATH: "/patient/fotona",
      ICON: <ImportOutlined className="menu-icon" />,
    },
    PAYMENTS: {
      LABEL: "Payments",
      PATH: "/patient/payments",
      ICON: <CreditCardOutlined className="menu-icon" />,
    },
  },
  PUBLIC: {
    FAQ: {
      LABEL: "FAQ",
      PATH: "/faq",
    },
    WHAT_WE_DO: {
      LABEL: "What We Do",
      PATH: "/whatWeDo",
    },
    CONTACT_US: {
      LABEL: "Contact Us",
      PATH: "/contactUs",
    },
    FOR_PATIENTS: {
      LABEL: "For Patients",
      PATH: "/forPatients",
    },
    FOR_PROVIDERS: {
      LABEL: "For Providers",
      PATH: "/forProviders",
    },
    COGNITIVE_JOURNEY: {
      LABEL: "Cognitive Journey",
      PATH: "/cognitiveJourney",
    },
    PRIVACY_POLICY: {
      LABEL: "Privacy Policy",
      PATH: "/privacyPolicy",
    },
    VIDEOS: {
      LABEL: "Sensational Videos",
      PATH: "/videos",
    },
    SHOW_VIDEO: {
      LABEL: "Video",
      PATH: "/video/:id",
    },
    RESPIRATORY_CARE: {
      LABEL: "Respiratory Care",
      PATH: "/respiratoryCare",
    },
    TINNITUS: {
      LABEL: "Tinnitus",
      PATH: "/tinnitus",
    },
  },
  PROVIDER: {
    LOGIN: {
      LABEL: "Provider Login",
      PATHS: {
        EMAIL: "/provider/login",
        SEND_OTP: "/provider/sendOtp",
        VERIFY_OTP: "/provider/verifyOtp",
        RESEND_OTP: "/provider/resendOtp",
      },
    },
    FORGOT_PASSWORD: {
      LABEL: "Forgot Password",
      PATH: "/provider/forgotPassword",
    },
    RESET_PASSWORD: {
      LABEL: "Reset Password",
      PATH: "/provider/resetPassword",
    },
    REGISTER: {
      LABEL: "Register",
      PATH: "/provider/registration",
    },
    PROFILE: {
      LABEL: "Profile",
      PATH: "/provider/profile",
      ICON: <EditOutlined className="menu-icon" />,
    },
    ALERTS: {
      LABEL: "Alerts",
      PATH: "/provider/alerts",
      ICON: <BellOutlined className="menu-icon" />,
    },
    VITAL_ALERTS: {
      LABEL: "Vital Alerts",
      PATH: "/provider/vitalAlerts",
      ICON: <BellOutlined className="menu-icon" />,
    },
    OTHER_ALERTS: {
      LABEL: "Other Alerts",
      PATH: "/provider/otherAlerts",
      ICON: <BellOutlined className="menu-icon" />,
    },
    CLAIMS: {
      LABEL: "Claims",
      PATH: "/provider/claims",
      ICON: <ProfileOutlined className="menu-icon" />,
    },
    VIALS: {
      LABEL: "Vials",
      PATH: "/provider/vials",
      ICON: <ExperimentOutlined className="menu-icon" />,
    },
    DASHBOARD: {
      LABEL: "Dashboard",
      PATH: "/provider/dashboard",
      ICON: <HomeOutlined className="menu-icon" />,
    },
    PATIENTS: {
      LABEL: "Patients",
      PATH: "/provider/patients",
      ICON: <TeamOutlined className="menu-icon" />,
    },
    ACTIVE_PATIENTS: {
      LABEL: "Active Patients",
      PATH: "/provider/activePatients",
      ICON: <MedicineBoxOutlined className="menu-icon" />,
    },
    COMPLETED_PROGRAMS: {
      LABEL: "Completed Programs",
      PATH: "/provider/completedPrograms",
      ICON: <SafetyOutlined className="menu-icon" />,
    },
    PATIENT_DETAILS: {
      LABEL: "Patient Details",
      ACTUAL_PATH: "/provider/viewPatient/:phone/:service/:name",
      PATH: "/provider/viewPatient/",
    },
  },
  CLINIC_ADMIN: {
    LOGIN: {
      LABEL: "Clinic Admin Login",
      PATHS: {
        EMAIL: "/clinicAdmin/login",
        SEND_OTP: "/clinicAdmin/sendOtp",
        VERIFY_OTP: "/clinicAdmin/verifyOtp",
        RESEND_OTP: "/clinicAdmin/resendOtp",
      },
    },
    FORGOT_PASSWORD: {
      LABEL: "Forgot Password",
      PATH: "/clinicAdmin/forgotPassword",
    },
    RESET_PASSWORD: {
      LABEL: "Reset Password",
      PATH: "/clinicAdmin/resetPassword",
    },
    DASHBOARD: {
      LABEL: "Dashboard",
      PATH: "/clinicAdmin/dashboard",
      ICON: <HomeOutlined className="menu-icon" />,
    },
    PROVIDERS: {
      LABEL: "Providers",
      PATH: "/clinicAdmin/providers",
      ICON: <UsergroupAddOutlined className="menu-icon" />,
    },
    PATIENTS: {
      LABEL: "Patients",
      PATH: "/clinicAdmin/patients",
      ACTUAL_PATH: "/clinicAdmin/patients/:service",
      ICON: <TeamOutlined className="menu-icon" />,
    },
    COMPLETED_PROGRAMS: {
      LABEL: "Completed Programs",
      PATH: "/clinicAdmin/completedPrograms",
      ICON: <SolutionOutlined className="menu-icon" />,
    },
    SLEEP: {
      LABEL: "Sleep Study",
      PATH: "/clinicAdmin/sleep",
      ICON: <StrikethroughOutlined className="menu-icon" />,
      CHILDREN: [
        {
          LABEL: "Locations",
          PATH: "/clinicAdmin/sleep/locations",
          ICON: <EnvironmentOutlined className="menu-icon" />,
        },
        {
          LABEL: "Bookings",
          PATH: "/clinicAdmin/sleep/bookings",
          ICON: <UnorderedListOutlined className="menu-icon" />,
        },
        {
          LABEL: "Manual Book",
          PATH: "/clinicAdmin/sleep/manualBook",
          ICON: <ImportOutlined className="menu-icon" />,
        },
        {
          LABEL: "Devices",
          PATH: "/clinicAdmin/sleep/devices",
          ICON: <ClusterOutlined className="menu-icon" />,
        },
      ],
    },
    SLEEP_LOCATIONS: {
      LABEL: "Locations",
      PATH: "/clinicAdmin/sleep/locations",
      ICON: <EnvironmentOutlined className="menu-icon" />,
    },
    SLEEP_BOOKINGS: {
      LABEL: "Bookings",
      PATH: "/clinicAdmin/sleep/bookings",
      ICON: <UnorderedListOutlined className="menu-icon" />,
    },
    SLEEP_MANUAL_BOOK: {
      LABEL: "Manual Book",
      PATH: "/clinicAdmin/sleep/manualBook",
      ICON: <ImportOutlined className="menu-icon" />,
    },
    SLEEP_DEVICES: {
      LABEL: "Devices",
      PATH: "/clinicAdmin/sleep/devices",
      ICON: <ClusterOutlined className="menu-icon" />,
    },
    FOTONA: {
      LABEL: "Fotona",
      PATH: "/clinicAdmin/fotona",
      ICON: <ContactsOutlined className="menu-icon" />,
      CHILDREN: [
        {
          LABEL: "Locations",
          PATH: "/clinicAdmin/fotona/locations",
          ICON: <EnvironmentOutlined className="menu-icon" />,
        },
        {
          LABEL: "Bookings",
          PATH: "/clinicAdmin/fotona/bookings",
          ICON: <UnorderedListOutlined className="menu-icon" />,
        },
      ],
    },
    FOTONA_LOCATIONS: {
      LABEL: "Locations",
      PATH: "/clinicAdmin/fotona/locations",
      ICON: <EnvironmentOutlined className="menu-icon" />,
    },
    FOTONA_BOOKINGS: {
      LABEL: "Bookings",
      PATH: "/clinicAdmin/fotona/bookings",
      ICON: <UnorderedListOutlined className="menu-icon" />,
    },
    DEVICES: {
      LABEL: "Devices",
      ACTUAL_PATH: "/clinicAdmin/devices/:mrn",
      PATH: "/clinicAdmin/devices/",
    },
  },
};

export const NOTIFICATIONS = {
  ERROR: {
    KEY: "error",
    TITLE: "Error!",
    MESSAGE: "Someting went wrong. Try again.",
    INVALID_PASSWORD: "Invalid Password",
    INVALID_OTP: "Invalid Passcode",
  },
  SUCCESS: {
    KEY: "success",
    TITLE: "Success!",
  },
  WARNING: {
    KEY: "warning",
    TITLE: "Warning!",
    INVALID_URL: "Invalid URL",
  },
  INFO: {
    KEY: "info",
    TITLE: "Info!",
    LOGGED_IN: "You have already logged in",
  },
};

export const VALIDATIONS = {
  DEFAULT: {
    BLANK: "Cannot be blank",
    INVALID_EMAIL: "Invalid Email Address",
    INVALID_NUMBER: "Invalid Number",
    PASSWORD_MIN_LENGTH: "Minimum length should be 8",
    PASSWORD_MISMATCH: "Both password should be same",
  },
  PATTERN: {
    NUMERIC: "Only numbers are allowed",
    BP: "Invalid BP format",
    ICD_CODE: "Invalid ICD-10 Code format",
    STRING: "Only alphabets are allowed",
    ALPHA_NUMERIC: "Only alphanumerics are allowed",
  },
};

export const FORM_FIELDS = {
  NAME: "Name",
  EMAIL: "Email",
  EMAIL_ADDRESS: "Email Address",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  PHONE: "Cell Phone Number",
  PRIMARY_INSURANCE: "Primary Insurance",
  INSURANCE: "Insurance",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  GENDER: "Gender at Birth",
  AGE: "Age",
  DOB: "Date Of Birth",
  PROVIDER: "Provider",
  MRN: "MRN",
  SURGERY_LOCATION: "Surgery Location",
  ADDRESS: "Address",
  ADDRESS_LINE_1: "Address Line 1",
  ADDRESS_LINE_2: "Address Line 2",
  CITY: "City",
  STATE: "State",
  ZIPCODE: "Zipcode",
  OTP: "One Time Passcode",
  SPECIALIZATION: "Specialization",
  DEPARTMENT: "Department",
  CATEGORY: "Category",
  CATEGORIES: "Categories",
  LICENSE: "License",
  SURGERY_DATE: "Surgery Date",
  SUB_CATEGORY: "Sub Category",
  CLINIC: "Clinic",
  CLINICS: "Preferred Clinics",
  CLINIC_NAME: "Clinic Name",
  ROLE: "Role",
  ETHNICITY: "Ethnicity",
  WEIGHT: "Weight(kg)",
  HEIGHT: "Height(cm)",
  LBS_WEIGHT: "Weight(lbs)",
  FEET_HEIGHT: "Height(feet and inches)",
  SKIN_COLOR: "Skin Colour",
  PAST_EMERGENCY_ADMIT: "Past Emergency Admit(< 6 Months)",
  STAY_DURATION: "Duration Of Stay(In Days)",
  ALCOHOL_STATUS: "Alcohol Status",
  SMOKING_STATUS: "Smoking Status",
  DISCHARGE_TO: "Discharged to Home/SVC",
  PROCESS_TYPES: "Process Types",
  BLOOD_GROUP: "Blood Group",
  ECG_REPORT: "ECG Report",
  ADMITTED_FROM_NON_HEALTHCARE: "Admitted from Non-healthcare",
  ADMIT_TYPE: "Admit Type",
  BED_TYPES: "Bed Types",
  SERUM_POTASSIUM: "Serum Potassium(mEq/L)",
  BLOOD_UREA_NITROGEN: "Blood Urea Nitrogen(mg/dL)",
  SERUM_SODIUM: "Serum Sodium(mEq/L)",
  BLOOD_GLUCOSE: "Blood Glucose(mg/dL)",
  HAEMOGLOBIN_LEVEL: "Haemoglobin Level(g/dL)",
  RESPIRATORY_RATE: "Respiratory Rate(brpm)",
  HEART_RATE: "Heart Rate(bpm)",
  BLOOD_PRESSURE: "Blood Pressure(mm/Hg)",
  SPO2: "SpO2(%)",
  COMORBIDITIES: "Comorbidities",
  PRIMARY_DIAGNOSIS: "Primary Diagnosis Codes",
  ADMISSION_DIAGNOSIS: "Present on Admission Diagnosis Codes",
  MEDICINE: "Medicines",
  SLECTED_MEDS: "Selected Medicines",
  PULSE: "Pulse",
  CREATED_ON: "Created On",
  LOCATION: "Location",
  SELF_PAY: "Self Pay",
  DEVICES: "Total Devices",
  SERVICES: "Services",
  PROGRAMS: "Programs",
};

export const PLACEHOLDER = {
  EMAIL_ADDRESS: "john@example.com",
  PASSWORD: "Minimum 8 characters",
  CONFIRM_PASSWORD: "Same as Password",
  PAST_EMERGENCY_ADMIT: "0",
  STAY_DURATION: "1",
  PLEASE_SELECT: "Please Select",
  WEIGHT: "65.5",
  HEIGHT: "170.5",
  SERUM_POTASSIUM: "3.6,5.4,6.5,4.5,5.6,5.1,5.0,4.0",
  BLOOD_UREA_NITROGEN: "5,6,15,20,18",
  SERUM_SODIUM: "135,136,137,140,140.5,136.5,147.5",
  BLOOD_GLUCOSE: "110,120,130,90,123,145,150,141",
  HAEMOGLOBIN_LEVEL: "12.3,14,5,12.6,15.6,12.6,14.5",
  RESPIRATORY_RATE: "13.5,16.5,17.4,16.4,12.3,12.9,14.8",
  HEART_RATE: "60,80,64,74,68,70,80,90",
  BLOOD_PRESSURE: "120/80,125/85,124/75,130/90",
  SPO2: "91,92,93,94,95,96,97,98,99",
  PRIMARY_DIAGNOSIS: "I69.3,I69,I68.9,A01.1",
  ADMISSION_DIAGNOSIS: "I69.3,I69,I68.9,A01.1",
  MEDICINE: "Search Medicines By Name",
};

export const BUTTONS = {
  REGISTER: "Register",
  SEND: "Send",
  RESET: "Reset",
  SUBMIT: "Submit",
  VERIFY: "Verify",
  SAVE: "Save",
  UPDATE: "Update",
  CONFIRM: "Confirm",
  CONTINUE: "Continue",
  LOGIN: "Login",
  ADD: "Add",
  VIEW: "View",
  VIEW_PATIENT: "View Patient",
  CANCEL: "Cancel",
  EDIT: "Edit",
  ACTIVATE: "Activate",
  PLACE_ORDER: "Order Online",
  ORDER: "Order",
  OK: "Ok",
  UNLINK: "Unlink",
  UNREGISTER: "Unregister",
  EXPERIENCE_NOW: "Experience Now",
  VIEW_DASHBOARD: "View Dashboard",
  NEXT: "Next",
  RESCHEDULE: "Reschedule",
  PAY: "Pay",
  LEARN_MORE: "Learn More",
  BOOK_NEW: "Book New Device",
  LEASE_DEVICE: "Lease Sleep Home Device",
  DONE: "Done",
};

export const PDF_WORKER = {
  URL: "https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js",
};

export const FOOTER = {
  PHONE: "+1(415)362-5443",
  EMAIL: "support@saigeware.com",
  COPYRIGHT: `Copyright &copy; ${new Date().getFullYear()}. Saigeware Inc. All rights reserved.`,
};

export const SERVICES = {
  PRE_POST_PROCEDURE: "Pre Post Procedure",
  GENERAL: "General",
  WELLNESS: "Wellness",
  MENTAL_WELLNESS: "Mental Wellness",
  SLEEP: "Sleep",
  TINNITUS: "Tinnitus",
  LEASE_DEVICE: "Lease Device",
  BIOLOGICS: "Biologics",
  SLIT: "SLIT",
  RING: "Ring",
};

export const USER_TYPES = {
  PATIENT: "PATIENT",
  PROVIDER: "PROVIDER",
  CLINIC_ADMIN: "CLINIC_ADMIN",
};

export const ALLERGY_TYPES = {
  SLIT: "SLIT",
  BIOLOGICS: "Biologics",
};

export const ROLES = {
  CLINIC_ADMIN: "Admin",
  PROVIDER: "Provider",
  STAFF: "Clinical Staff",
  SLEEP_STAFF: "Sleep Staff",
  OTHER: "Other",
};
